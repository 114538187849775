import React, { useState, useEffect } from 'react';
import './Modal.css'; 
import Image from './img/cookies.png';

function Modal() {
    const [showModal, setShowModal] = useState(false);
    const [showCustomizeModal, setShowCustomizeModal] = useState(false);
    const [showPoliticaModal, setShowPoliticaModal] = useState(false);

    useEffect(() => {
        const cookiesAccepted = localStorage.getItem('cookiesAccepted');
        if (!cookiesAccepted) {
            setShowModal(true);
        }
    }, []);

    const handleAccept = () => {
        setShowModal(false);
        localStorage.setItem('cookiesAccepted', 'true');
    };

    const handleCustomize = () => {
        setShowCustomizeModal(true);
        setShowModal(false);
    };

    const openPoliticaModal = () => {
        setShowPoliticaModal(true);
        setShowModal(false);
    };

    const handlePoliticaModalClose = () => {
        setShowPoliticaModal(false);
        setShowModal(true);
    };

    return (
        <>
            <div id="cookieModal" className={`modal ${showModal ? 'show' : ''}`}>
                <div className="modal-content">
                    <h2>Valoramos su privacidad</h2>
                    <img src={Image} alt="cookie" />
                    <p>
                        Usamos cookies para mejorar su experiencia de navegación, mostrarle anuncios o contenidos personalizados y analizar nuestro tráfico. 
                        Al hacer clic en “Aceptar todo” usted brinda su consentimiento a nuestro uso de las cookies. <a onClick={openPoliticaModal} className='boton-p'>Política de cookies</a>
                    </p>
                    <div className="button-container">
                        <button id="acceptBtn" className="modal-button accept" onClick={handleAccept}>Aceptar</button>
                        <button id="customizeBtn" className="modal-button customize" onClick={handleCustomize}>Personalizar</button>
                    </div>
                </div>
            </div>
            {showCustomizeModal && <CustomizeModal setShowCustomizeModal={setShowCustomizeModal} />}
            {showPoliticaModal && <PoliticaModal handleClose={handlePoliticaModalClose} />}
        </>
    );
}

function CustomizeModal({ setShowCustomizeModal }) {
    const [preferences, setPreferences] = useState({
        optionB: false,
        optionC: false,
        optionD: false,
        optionE: false,
    });

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setPreferences(prevPreferences => ({
            ...prevPreferences,
            [name]: checked
        }));
    };

    const handleSavePreferences = () => {
        console.log(preferences);
        setShowCustomizeModal(false);
        localStorage.setItem('cookiesAccepted', 'true');
    };

    const handleAcceptAll = () => {
        setPreferences({
            optionB: true,
            optionC: true,
            optionD: true,
            optionE: true,
        });
        setShowCustomizeModal(false);
        localStorage.setItem('cookiesAccepted', 'true');
    };

    return (
        <div id="customizeModal" className="modal show">
            <div className="modal-content">
                <h2>Personalizar las Preferencias de Cookies y de Consentimiento</h2>
                <p>
                    Usamos cookies para ayudarle a navegar de manera correcta y realizar ciertas funciones. 
                    Encontrará información detallada sobre cada una de las cookies y el consentimiento que posean a continuación.
                </p>
                <p>
                    Las cookies “Necesarias” son las que le permiten navegar por la página web y usar sus funciones esenciales.
                </p>
                <p>
                    También utilizamos cookies “de terceros”. Estas nos ayudan a recabar información importante de los usuarios, 
                    como guardar sus preferencias y aportar el contenido y la publicidad que le sean relevante. Estas cookies 
                    se guardan en su navegador previo consentimiento por su parte.
                </p>
                <p>
                    Puede optar por activar o desactivar alguna de estas cookies, aunque la desactivación de algunas podría 
                    afectar a su experiencia de navegación.
                </p>
                <p>
                    <strong>SU PREFERENCIA: Active el botón según su preferencia.</strong>
                </p>
                <div className="checkbox-container">
                    <label>
                        A) Necesaria (Siempre activas)
                        <span>Las cookies necesarias son cruciales para las funciones básicas del sitio web. Estas cookies no almacenan ningún dato de identificación personal.</span>
                        <input type="checkbox" name="optionA" checked={true} disabled />
                    </label>
                    <label>
                        B) Funcional
                        <span>Las cookies funcionales ayudan a realizar ciertas funcionalidades, como compartir el contenido del sitio web en plataformas de redes sociales, recopilar comentarios y otras características de terceros.</span>
                        <input type="checkbox" name="optionB" checked={preferences.optionB} onChange={handleCheckboxChange} />
                    </label>
                    <label>
                        C) Analítica
                        <span>Las cookies analíticas se utilizan para recopilar información estadística sobre el uso que efectúan los usuarios del sitio web.</span>
                        <input type="checkbox" name="optionC" checked={preferences.optionC} onChange={handleCheckboxChange} />
                    </label>
                    <label>
                        D) Rendimiento
                        <span>Las cookies de rendimiento se utilizan para comprender y analizar los índices de rendimiento clave del sitio web, lo que ayuda a proporcionar una mejor experiencia de usuario para los visitantes.</span>
                        <input type="checkbox" name="optionD" checked={preferences.optionD} onChange={handleCheckboxChange} />
                    </label>
                    <label>
                        E) Anuncios
                        <span>Las cookies publicitarias se utilizan para entregar a los visitantes anuncios personalizados basados en las páginas que visitaron antes y analizar la efectividad de la campaña publicitaria.</span>
                        <input type="checkbox" name="optionE" checked={preferences.optionE} onChange={handleCheckboxChange} />
                    </label>
                </div>
                <div className="button-container">
                    <button className="modal-button save" onClick={handleSavePreferences}>Guardar Preferencias</button>
                    <button className="modal-button accept-all" onClick={handleAcceptAll}>Aceptar Todo</button>
                </div>
            </div>
        </div>
    );
}

function PoliticaModal({ handleClose }) {
    return (
        <div id="politicaModal" className="modal show">
            <div className="modal-content">
                <a><span className="close" onClick={handleClose}>&times;</span></a>
                
                <h2>Política de Cookies</h2>
                <p>
                    DATASHIELD utiliza ‘cookies’ para reunir información sobre las visitas que los usuarios hagan en nuestro sitio web.
                    Una cookie o galleta informática es un pequeño archivo de información que se guarda en su ordenador, “smartphone” o
                    tableta cada vez que visita nuestra página web. En principio, una cookie es inofensiva: no contiene virus, troyanos,
                    gusanos, etc. que puedan dañar tu terminal, pero sí tiene cierto impacto sobre tu derecho a la protección de tus datos
                    personales, pues recoge determinada información concerniente a tu persona (hábitos de navegación, identidad, preferencias, etc.).
                    Es por ello que, en base a lo establecido en la normativa aplicable (normativa vigente de protección de datos personales),
                    la activación de determinados tipos de cookies necesitará de tu autorización previa. Antes de ello, te daremos alguna
                    información adicional que te ayudará a una mejor toma de decisiones al respecto:
                    <br /><br />
                    Las cookies pueden ser de varios tipos en función de su finalidad:
                    <br /><br />
                    Las cookies técnicas son necesarias para que nuestra página web pueda funcionar, no necesitan de tu autorización y son
                    las únicas que tenemos activadas por defecto.
                    <br /><br />
                    El resto de cookies sirven para mejorar nuestra página, para personalizarla en base a tus preferencias, o para poder
                    mostrarte publicidad ajustada a tus búsquedas, gustos e intereses personales. Puedes aceptar todas estas cookies pulsando
                    el botón ACEPTA TODO o configurar su uso clicando en el apartado CONFIGURACIÓN DE COOKIES.
                    <br /><br />
                    Si continúas navegando, aceptas nuestro uso de cookies.
                </p>
            </div>
        </div>
    );
}

export default Modal;
