import React, { useEffect } from 'react';
import './PoliticasPrivacidad.css'; 



function PoliticasPrivacidad() {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
    return (

        <div className="privacy-policy">
      <h1>POLÍTICA DE PRIVACIDAD</h1>
      <p>
        La política de privacidad de DATASHIELD asegura al usuario que toda la información que incorpore al sitio de DATASHIELD será conservada de manera confidencial y segura.
      </p>

      <h2>COMPROMISO</h2>
      <p>
        DATASHIELD se compromete a respetar y proteger sus datos personales.
      </p>
      <p>
        La siguiente Política de Privacidad describe el tratamiento de los datos recolectados mediante:
      </p>
      <ul>
        <li>
          a) La página web www.datashieldpdp.com y los formularios de carga de datos personales.
        </li>
        <li>
          b) Mediante los e-mails enviados por DATASHIELD que hagan referencia a esta Política de Privacidad.
        </li>
        <li>
          c) Las redes sociales oficiales que posee DATASHIELD.
        </li>
        <li>
          d) Por Whatsap.
        </li>
      </ul>

      <h2>USO DE DATOS PERSONALES</h2>
      <p>
        Se considera “Datos Personales” como Información de cualquier tipo referida a personas físicas o de existencia ideal determinadas o determinables.
      </p>
      <p>
        DATASHIELD no recolecta ni utiliza datos considerados sensibles para ningún fin.
      </p>
      <p>
        DATASHIELD en ningún caso tratará datos de menores de edad.
      </p>

      <h2>AUTORIZACIÓN PARA EL USO DE DATOS</h2>
      <p>
        La información personal que incorporen los Usuarios a DATASHIELD implica su aprobación y consentimiento y autoriza a DATASHIELD a utilizarlos automáticamente.
      </p>

      <h2>RECOLECCIÓN DE DATOS PERSONALES</h2>
      <p>
        DATASHIELD recolecta los siguientes Datos Personales de los usuarios:
      </p>
      <ul>
        <li>
          Datos identificativos: nombre, apellido, número telefónico, correo electrónico.
        </li>
        <li>
          En casos de envío de curriculum vitae al sector de RRHH de DATASHIELD: Datos académicos y profesionales: formación educacional, experiencia profesional, idiomas, entre otras cosas. El usuario garantiza que los Datos Personales brindados son verdaderos, exactos, completos y actualizados, siendo responsable de cualquier daño o perjuicio, directo o indirecto, que pudiera ocasionarse como consecuencia del incumplimiento de tal obligación.
        </li>
      </ul>

      <h2>CONSERVACIÓN DE LOS DATOS PERSONALES</h2>
      <p>
        DATASHIELD conservará los datos personales de los usuarios durante el tiempo necesario para la realización de las finalidades para las que fueron recolectados o mientras el usuario no revoque el consentimiento otorgado. Posteriormente, en caso de ser necesario, se conservará la información durante el período necesario para cubrir posibles responsabilidades.
      </p>
      <p>
        DATASHIELD posee protecciones razonables para garantizar la seguridad, confidencialidad de la información personal de sus Usuarios.
      </p>

      <h2>DERECHOS ACCESO, RECTIFICACIÓN, OPOSICIÓN, ACTUALIZACIÓN</h2>
      <p>
        Los Usuarios, titulares de los datos personales, podrán ejercer los Derechos de acceso, rectificación o cancelación de datos y oposición.
      </p>
      <p>
        Cuando hayamos obtenido su consentimiento para el tratamiento de sus datos personales, usted tiene derecho a revocarlo en cualquier momento.
      </p>
      <p>
        Para ejercer los derechos mencionados precedentemente, deberá contactarse a <a href="mailto:datospersonales@datashield.com">datospersonales@datashield.com</a>
      </p>
      <p>
        LA AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA, en su carácter de Órgano de Control de la Ley N° 25.326, tiene la atribución de atender las denuncias y reclamos que interpongan quienes resulten afectados en sus derechos por incumplimiento de las normas vigentes en materia de protección de datos personales.
      </p>

      <h2>OTRA INFORMACIÓN</h2>
      <p>
        La dirección de IP del usuario es automáticamente asignada a la computadora por su proveedor de servicios de internet. Al momento de utilizar los medios, la dirección de IP podrá ser identificada y cargada automáticamente en nuestros servidores, junto con el tiempo de visita y las páginas visitadas. Recolectar direcciones de IP es una práctica común y se realiza automáticamente por muchas páginas web, aplicaciones y otros servicios. Utilizamos las direcciones de IP para calcular los niveles de uso, diagnosticar problemas en el servidor y administrar los medios. DATASHIELD podrá conocer aproximadamente la localización de los usuarios a través de su dirección de IP.
      </p>

      <h2>SEGURIDAD</h2>
      <p>
        DATASHIELD ha adoptado y adoptará las medidas técnicas y organizativas necesarias para garantizar la protección de sus Datos Personales y evitar su alteración, pérdida, tratamiento y/o acceso no autorizado, cumpliendo en todo momento con la normativa aplicable.
      </p>

      <h2>COMUNICACIONES DE MARKETING</h2>
      <p>
        En caso de que el Usuario no desee seguir recibiendo correos electrónicos, podrá desuscribirse cambiando sus preferencias de e-mail.
      </p>

      <h2>INFORMACIÓN ACERCA DE LAS COOKIES</h2>
      <p>
        De manera breve, también queremos informarle que utilizamos cookies, y que puede encontrar toda la información detallada en nuestra Política de Cookies. A través de la misma, podrá conocer qué cookies utilizamos, con qué finalidades y más información de importancia en relación a las mismas.
      </p>

      <h2>ACTUALIZACIÓN LA POLÍTICA DE PRIVACIDAD</h2>
      <p>
        La Política de Privacidad de DATASHIELD podrá ser actualizada cuando así se considere. Dicha actualización será informada en el sitio web.
      </p>

      <h2>CONTACTO</h2>
      <p>
        DATASHIELD es la encargada de recolectar, usar y procesar los datos personales de conformidad con esta Política de Privacidad.
      </p>
      <p>
        En caso de tener alguna duda o consulta sobre esta Política de Privacidad contáctenos al e-mail <a href="mailto:datospersonales@datashieldpdp.com">datospersonales@datashieldpdp.com</a>
      </p>
    </div>
    );
}

export default PoliticasPrivacidad;
