import React, { useRef } from 'react';
import './Contact.css'; 
import contactImage from './img/logo.png'; 
import emailjs from 'emailjs-com';
import { Link } from 'react-router-dom';

function ContactSection() {
    const refForm = useRef();

    const enviarEmail = (event) => {
        event.preventDefault();

        const checkbox = refForm.current.querySelector('#privacyPolicy');
        if (!checkbox.checked) {
            alert('Debes aceptar la política de privacidad para enviar el mensaje.');
            return;
        }

        window.grecaptcha.ready(() => {
            window.grecaptcha.execute('6LerBuEpAAAAAE8jEw7PS3km_l6XHDlF_6K0PMLU', { action: 'submit' }).then((token) => {
                const serviceId = "service_v4r6wcb";
                const templateId = "template_05ggyaa";
                const apikey = "P10Hjg0ZYDpDsALqx";

                const formData = new FormData(refForm.current);
                formData.append('g-recaptcha-response', token);

                emailjs.sendForm(serviceId, templateId, refForm.current, apikey)
                    .then(result => {
                        console.log(result.text);
                        alert("Mensaje enviado");
                        refForm.current.reset();
                    })
                    .catch(error => console.log(error));
            });
        });
    }

    return (
        <div className="contact-section">
            <form className="contact-form" ref={refForm} onSubmit={enviarEmail} id="contact-form">
                <h3>Contacto</h3>
                <div className="form-group">
                    <label htmlFor="name">Nombre:</label>
                    <input type="text" id="name" name="name" required />
                </div>

                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input type="email" id="email" name="email" required />
                </div>

                <div className="form-group">
                    <label htmlFor="phone">Teléfono:</label>
                    <input type="tel" id="phone" name="phone" />
                </div>

                <div className="form-group">
                    <label htmlFor="message">Mensaje:</label>
                    <textarea id="message" name="message" required></textarea>
                </div>

                <div className="form-group privacyPolicy">
                    <input type="checkbox" id="privacyPolicy" name="privacyPolicy" />
                    <label htmlFor="privacyPolicy">Acepto la <Link to="/privacidad">Política de Privacidad</Link></label>
                </div>

                <button type="submit" className="button g-recaptcha" 
                    data-sitekey="6LerBuEpAAAAAE8jEw7PS3km_l6XHDlF_6K0PMLU" 
                    data-callback='onSubmit' 
                    data-action='submit'>Enviar</button>
            </form>
            <div className="info-card" data-aos="fade-right" data-aos-duration="2000">
                <h2>Dejanos tu consulta</h2>
                <img src={contactImage} alt="Descripción de la imagen" />
            </div>
        </div>
    );
}

export default ContactSection;
