import React from 'react';
import './Footer.css'; 
import Logo from './img/logo.png';
import { Link } from 'react-router-dom';

function Footer() {
    // Función para abrir el modal de política de cookies
    const handleOpenPoliticaModal = () => {
        var modal = document.getElementById("politicaModal");
        modal.style.display = "block";
    }

    // Función para cerrar el modal de política de cookies
    const handleClosePoliticaModal = () => {
        var modal = document.getElementById("politicaModal");
        modal.style.display = "none";
    }

    // Función para cerrar el modal de política de cookies cuando se hace clic fuera de él
    window.onclick = function(event) {
        var modal = document.getElementById("politicaModal");
        if (event.target === modal) {
            modal.style.display = "none";
        }
    }

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleLinkClick = () => {
        scrollToTop();
    };

    return (
        <>
            <footer>
                <div className="footer-content">
                    <div className="logo">
                        <a href="#"><img src={Logo} width={100} alt="Logo"></img></a>
                    </div>
                    <nav>
                        <ul>
                            <li><Link to="/" onClick={handleLinkClick}>Home</Link></li>
                            <li><Link to="/servicios" onClick={handleLinkClick}>Servicios</Link></li>
                            <li><Link to="/paquetes" onClick={handleLinkClick}>Paquetes</Link></li>
                            <li><Link to="/blog" onClick={handleLinkClick}>Blog</Link></li>
                            <li><Link to="/contacto" onClick={handleLinkClick}>Contáctenos</Link></li>
                            <li><Link to="/terminos" onClick={handleLinkClick}>Términos Legales</Link></li>
                            <li><a id="openPoliticaModal" onClick={handleOpenPoliticaModal}>Política de Cookies</a></li>
                            <li><Link to="/privacidad" onClick={handleLinkClick}>Política de Privacidad</Link></li>
                        </ul>
                    </nav>
                    <div className="legal">
                        <a href='mailto:info@datashieldpdp.com'>Trabaja con nosotros <br></br>Dejanos tu cv</a>
                        <div className='direcciones'>
                            <p>Domicilio: Av. La Plata 241 1 piso "C" Caba - Argentina C1184AAC</p>
                            <a href='mailto:info@datospersonalespdp.com'>info@datospersonalespdp.com</a>
                        </div>
                    </div>
                    <div className="copyright">
                        &copy; 2024 DATASHIELD
                    </div>
                </div>
            </footer>

            {/* Modal de política de cookies */}
            <div id="politicaModal" className="politica-modal">
                <div className="politica-modal-content">
                    <span className="politica-close" onClick={handleClosePoliticaModal}>&times;</span>
                    <h2>Política de Cookies</h2>
                    <p>
                        DATASHIELD utiliza ‘cookies’ para reunir información sobre las visitas que los usuarios hagan en nuestro sitio web.
                        Una cookie o galleta informática es un pequeño archivo de información que se guarda en su ordenador, “smartphone” o
                        tableta cada vez que visita nuestra página web. En principio, una cookie es inofensiva: no contiene virus, troyanos,
                        gusanos, etc. que puedan dañar tu terminal, pero sí tiene cierto impacto sobre tu derecho a la protección de tus datos
                        personales, pues recoge determinada información concerniente a tu persona (hábitos de navegación, identidad, preferencias, etc.).
                        Es por ello que, en base a lo establecido en la normativa aplicable (normativa vigente de protección de datos personales),
                        la activación de determinados tipos de cookies necesitará de tu autorización previa. Antes de ello, te daremos alguna
                        información adicional que te ayudará a una mejor toma de decisiones al respecto:
                        <br /><br />
                        Las cookies pueden ser de varios tipos en función de su finalidad:
                        <br /><br />
                        Las cookies técnicas son necesarias para que nuestra página web pueda funcionar, no necesitan de tu autorización y son
                        las únicas que tenemos activadas por defecto.
                        <br /><br />
                        El resto de cookies sirven para mejorar nuestra página, para personalizarla en base a tus preferencias, o para poder
                        mostrarte publicidad ajustada a tus búsquedas, gustos e intereses personales. Puedes aceptar todas estas cookies pulsando
                        el botón ACEPTA TODO o configurar su uso clicando en el apartado CONFIGURACIÓN DE COOKIES.
                    </p>
                </div>
            </div>
        </>
    );
}

export default Footer;
