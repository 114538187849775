import React, { useEffect } from 'react';
import './TerminosLegales.css'; 

function TerminosLegales() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="privacy-policy">
      <h1>TÉRMINOS LEGALES</h1>

      <h2>CONDICIONES DE USO</h2>
      <p>
        El uso del sitio Web www.datashieldpdp.com (en adelante, el “Sitio Web”) se rige por las normas establecidas en el presente aviso legal. La utilización del mismo implica que usted acepta dichas condiciones. Puede contactarse en la dirección abajo indicada o a través de la dirección de correo electrónico: <a href="mailto:info@datashieldpdp.com">info@datashieldpdp.com</a>
      </p>

      <h2>DATOS IDENTIFICATIVOS</h2>
      <p>
        Se indican los datos de información general de este sitio Web.
      </p>
      <ul>
        <li><strong>Titular:</strong> DATASHIELD.</li>
        <li><strong>Domicilio:</strong> Av. La Plata 241 1 C CABA CP C1184AAC Argentina.</li>
        <li><strong>Contacto:</strong> <a href="mailto:info@datashieldpdp.com">info@datashieldpdp.com</a></li>
      </ul>

      <h2>CONDICIONES DEL SITIO WEB</h2>
      <p>
        Los contenidos y servicios del Sitio Web son proporcionados por DATASHIELD.
      </p>
      <p>
        El uso y la navegación por el Sitio Web implica aceptar y conocer las advertencias legales, condiciones y términos de uso que el mismo contiene, especial el presente Aviso Legal, la Política de Protección de Datos, la aceptación de cookies, etc.
      </p>
      <p>
        DATASHIELD se reserva el derecho a actualizar, modificar o eliminar la información de este Sitio Web, pudiendo a tal fin limitar o no permitir el acceso al Sitio Web.
      </p>

      <h2>DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL</h2>
      <p>
        Todos los contenidos del Sitio Web incluyendo a título enunciativo pero no limitativo las marcas, denominaciones, logotipos, imágenes, textos, diseño, y cualquier otro signo distintivo del Sitio Web pertenecen a DATASHIELD. El acceso a dichos contenidos o elementos no otorga al usuario el derecho de alteración, modificación, explotación o reproducción, distribución, comunicación pública o cualquier otro derecho que corresponda al titular del derecho afectado. El usuario se compromete a no realizar en ningún caso una explotación comercial, directa o indirecta de los mismos. Todo uso no autorizado del Sitio Web dará derecho a DATASHIELD para ejercitar las acciones que correspondan en virtud de la legislación aplicable.
      </p>

      <h2>PROTECCIÓN DE DATOS</h2>
      <p>
        Lea nuestra Política de Protección de Datos respecto a los datos que se recogen en nuestro Sitio Web.
      </p>

      <h2>LIMITACIÓN DE RESPONSABILIDADES</h2>
      <p>
        Bajo ninguna circunstancia DATASHIELD será responsable ante terceros por cualquier daño directo o indirecto, económico o no resultado del uso del Sitio Web. DATASHIELD no garantiza ni se hace responsable de la ausencia de virus y/o demás componentes dañinos en el Sitio Web o en el servidor que lo suministra, los datos o perjuicios que cause, asímismo o a un tercero, cualquier persona que infringiera las condiciones, normas e instrucciones que DATASHIELD establece en el Sitio Web o a través de la vulneración de los sistemas de seguridad. DATASHIELD declara que ha adoptado todas las medidas necesarias, dentro de sus posibilidades y del estado de la tecnología, para garantizar el funcionamiento del Sitio Web y evitar la existencia y transmisión de virus y demás componentes dañinos a los Usuarios.
      </p>
      <p>
        DATASHIELD se reserva el derecho a emprender las acciones legales que estime oportunas contra las personas que utilicen el Sitio Web de manera distinta a la estipulada o que pudiera dañar la imagen de DATASHIELD de terceros y a exigirles una indemnización por los daños y perjuicios que se deriven de reclamaciones, acciones o demandas de terceros (incluyendo sin limitación honorarios de abogados) como consecuencia de las acciones anteriores u otras que se pudieran producir.
      </p>

      <h2>JURISDICCIÓN Y LEYES APLICABLES</h2>
      <p>
        Las Condiciones Generales declaradas en este sitio Web se regirán por la legislación argentina. DATASHIELD y el usuario, con renuncia expresa al fuero que pudiera corresponderles, se someten a la Jurisdicción de los Juzgados y Tribunales de Argentina.
      </p>
    </div>
  );
}

export default TerminosLegales;
