import image1 from './img-blog/artificial-ia.png';
import image2 from './img-blog/blog-2.png';


const data = [
  {
    id: 1,
    title: 'Artificial intelligence (AI) act: Council gives final green light to the first worldwide rules on AI',
    date: '21 mayo 2024 10:17',
    content: 'Today the Council approved a ground-breaking law aiming to harmonise rules on artificial intelligence...',
    image: image1,
    url: 'https://www.consilium.europa.eu/es/press/press-releases/2024/05/21/artificial-intelligence-ai-act-council-gives-final-green-light-to-the-first-worldwide-rules-on-ai/'
  },
  {
    id: 2,
    title: '133rd Session of the Committee of Ministers',
    date: 'Strasbourg, 17 May 2024',
    content: 'Council of Europe Framework Convention on Artificial Intelligence and Human Rights, Democracy and the Rule of Law',
    image: image2,
    url: 'https://search.coe.int/cm#{%22CoEObjectId%22:[%220900001680afb11f%22],%22sort%22:[%22CoEValidationDate%20Descending%22]}'
  }
];

export default data;
