import React from "react";
import "./PackagesContainer.css";
import { Link } from 'react-router-dom';

function PackagesContainer() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleLinkClick = () => {
    scrollToTop();
  };

  return (
    <div className="container">
      <h2>Nuestros Paquetes</h2>
      <div className="card-container">
        <div className="card">
          <h3>PACK INDIVIDUAL 1</h3>
          <p>2 hs de reunión sesión online</p>
          <p>
            Supresión- Rectificación - Acceso de los antecedentes de deudas de
            los informes comerciales vigentes como Veraz, Banco Central,
            Nosis, etc por aplicación de la Ley 25.326.
          </p>
          <p>
            Atención solicitudes Derechos ARCO+ (Acceso- Supresión-
            Rectificación- de datos Personales).
          </p>
          <p>
            Asistencia y defensa jurídica en caso de denuncias y reclamaciones
            de Protección de Datos Personales.
          </p>
          <p>Acceso a historia clínica</p>
          <Link className="button" to="/contacto" onClick={handleLinkClick}>Consultar</Link>
        </div>
        <div className="card">
          <h3>PACK BÁSICO EMPRESA</h3> <p className="tittle-dos"><strong>Solo se abona una vez</strong></p> <p>2 hs de reunión sesión online</p>
          <p>Políticas de Cookies</p> <p>Términos y Condiciones</p>
          <p>Políticas de Privacidad</p> <p>Registro de Bases de Datos</p>
          <p>Registro de Software</p> <p>Registro de Marca</p>
          <Link className="button" to="/contacto" onClick={handleLinkClick}>Consultar</Link>
        </div>
        <div className="card">
          <h3>PACK MEDIUM EMPRESA</h3> <p className="tittle-dos"><strong>Solo se abona una vez</strong></p> <p>2 hs de reunión sesión online</p>
          <p>Políticas de Cookies</p> <p>Términos y Condiciones</p>
          <p>Políticas de Privacidad</p> <p>Registro de Bases de Datos</p>
          <p>Registro de Software</p> <p>Registro de Marca</p>
          <p>Servicio de Asesoramiento documental</p>
          <p>
            Evaluación situación actual de la empresa a nivel seguridad de la
            información, acceso de datos, protección de los empleados
          </p>
          <p>
            Revisión cumplimiento de políticas internas de protección de
            datos.
          </p>
          <Link className="button" to="/contacto" onClick={handleLinkClick}>Consultar</Link>
        </div>
        <div className="card">
          <h3>
            PACK CAPACITACIÓN PARA ENTIDADES FINANCIERAS, EMPRESAS Y
            ORGANIZACIONES sobre CIBERSEGURIDAD Y SEGURIDAD DE LA INFORMACIÓN
          </h3>
          <p>
            Capacitación y concientización a empleados sobre seguridad
            cibernética que incluye: Descarga de archivos adjuntos infectados
            con malware desde correos electrónicos, visitas a sitios web poco
            confiables, uso de contraseñas poco seguras,actualización
            regular de contraseñas, envío de correos electrónicos a
            destinatarios incorrectos por error.
          </p>
          <p>
            Desarrollo de la cultura de seguridad, aumento de las defensas
            tecnológicas contra las ciberamenazas, cumplimiento de las
            normativas, responsabilidad social y corporativa.
          </p>
          <p>
            Reducción del riesgo de filtración de datos y ataques de
            suplantación de identidad (phishing)
          </p>
          <Link className="button" to="/contacto" onClick={handleLinkClick}>Consultar</Link>
        </div>
      </div>
    </div>
  );
}

export default PackagesContainer;
